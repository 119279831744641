<template>
    <div>
      <div class="table_sty">
        <div class="froms" v-if="!id">
          <p class="describe">筛选</p>
          <div class="segmentation"></div>
          <div class="from_submit">
            <el-form label-width="120px" :model="formLabelAlign" class="from">
              <el-form-item label="操作人：">
                <el-input
                  placeholder="请输入姓名"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.name"
                ></el-input>
              </el-form-item>
  
              <el-form-item label="时间选择：">
                <div>
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="formLabelAlign.bengin_time"
                    type="date"
                    placeholder="开始日期"
                    @change="DateChange()"
                  >
                  </el-date-picker>
                  至
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="formLabelAlign.end_time"
                    type="date"
                    placeholder="结束日期"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-form>
            <div class="search_button">
              <div>
                <el-button
                  @click="way_tableData(true)"
                  type="primary"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-button @click="on_reset" icon="el-icon-refresh-left"
                  >重置</el-button
                >
              </div>
            </div>
          </div>
        </div>
  
        <div class="tab_sty">
          <div class="add-sty" v-if="id">
            <p>检查记录</p>
          </div>
          <div>
            <div>
              <el-table
                highlight-current-row
                :data="table_List"
                :header-cell-style="{ background: '#f8f8f9' }"
                style="flex: 1"
              >
                <!-- <el-table-column
                  label="序号"
                  type="index"
                  :index="indexMethod"
                ></el-table-column> -->
                <el-table-column
                  prop="department_name"
                  label="检查部门"
                ></el-table-column>
                <el-table-column
                  label="是否完成"
                  prop="status"
                >
                <template  slot-scope="scope">
                  <el-tag v-if="scope.row.status==0">未完成</el-tag>
                  <el-tag v-if="scope.row.status==1">已完成</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                  label="检查结果"
                  prop="hdanger_id"
                >
                <template  slot-scope="scope">
                  <el-tag v-if="scope.row.form_is_normal==1">正常</el-tag>
                  <el-tag v-if="scope.row.form_is_normal==2">隐患</el-tag>
                </template>
              </el-table-column>
               
                <el-table-column
                  prop="complete_time"
                  label="完成时间"
                ></el-table-column>
                  <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      class="list_sty"
                      @click="on_particulars(scope.row)"
                      >查看更多</el-link
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="paging">
              <el-pagination
                @current-change="way_page"
                :page-size="page_size"
                layout="prev, pager, next, jumper"
                :total="total"
                :current-page="page"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        
        <!-- 审批详情 -->
        <el-dialog :title="title" show-close :visible.sync="examine_show" center>
        <inspectionParticulars
          v-if="examine_show"
          ref="inspectionParticulars"
          :refbool.sync="examine_show"
          @way_tableData="way_tableData"
        />
      </el-dialog> 
       </div>
    </div>
  </template>
  <script>
  import {
    ay_control,
    hdangercheckpass,
    ay_addrecorder,
    hdangerestimateInfo,
    ay_recorderSave,
    hdangerestimateSave,
    casesestimateInfo,
    casesestimateSave,
    casescheckpass,
    inspectionControlLog
  } from "../../assets/request/api";
  import inspectionParticulars from "../control/inspection_particulars.vue";
  import newlycontrol from "../control/newly_control.vue";
  import CustomFromanalysis from "../../components/custom_From/custom_Fromanalysis.vue";
  import uploadingimg from "../../components/uploading/uploading_img.vue";
  import uploadingVideo from "../../components/uploading/uploading_Video.vue";
  import Bepartment from "../../components/Bepartment";
  export default {
    name: "inspection_logs",
    components: {
      inspectionParticulars,
      newlycontrol,
      CustomFromanalysis,
      Bepartment,
      uploadingimg,
      uploadingVideo,
    },
    props: {
      id: {
        type: String,
        default: "",
      },
      url_type: {
        type: String,
      },
      operation_status: {
        type: Number,
      },
    },
    data() {
      return {
        table_head: "检查日志",
        typName: "",
        cate: [],
  
        enter_type: "",
        drawer_type: 0,
        drawer: false,
        table_List: [],
        //条数
        page_size: 10,
        //页数
        page: 1,
        //总条数
        total: 0,
        formLabelAlign: {
          name: "",
          bengin_time: "",
          end_time: "",
        },
        timeData: [],
        //展示详情
        Data: "",
        type: "",
        //更多
        rowitem: {},
        com_imgList: [],
        //审批详情
        title: "",
        examine_show: false,
        //添加管控
        control_show: false,
        //自定义表单管控
        Custom_show: false,
        frmcode: "",
        formvalue: "",
        //统计周期结束时间的最低选择时间
        pickerOptions: {},
  
        //指派操作
        assess_pop: false,
        assess_Data: {
          id: "",
          level_color: "",
          cate_id: "",
          score: 0,
          status: "",
          department_id: "",
          scheme_id: "",
          checkedCities: [],
          end_time: "",
        },
        enable_list: [],
        level_color_list: [],
        level_list: [],
        scheme_list: [],
        office_position: [],
        cate: [],
        //整改
        newly_show: false,
        newly_From: {
          source_id: "",
          form_detail: "",
          video_List: [],
          img_List: [],
          form_is_normal: "1",
        },
        //验收
        check_show: false,
        check_From: {
          source_id: "",
          is_pass: "1",
          form_detail: "",
        },
      };
    },
    created() {
      let { type, control_id } = this.$route.query;
      this.typName = this.url_type == "hdanger" ? "隐患" : "事件";
      if (this.url_type == "regulation") {
        this.table_head = "填报";
      }
      //判断是任务还是管控
      if (type == "task") {
        this.on_newly();
        this.enter_type = type;
      } else if (
        type == "control" &&
        this.url_type != "hdanger" &&
        this.url_type != "cases"
      ) {
        this.formLabelAlign.id = control_id;
        this.enter_type = type;
      }
      if (this.id) {
        this.formLabelAlign.source_id = this.id;
      }
      this.way_tableData();
    },
  
    methods: {
      on_details(id) {
        this.$router.push({
          path: "/security/focus_particulars",
          query: {
            id: id,
          },
        });
      },
      //统计周期结束日期禁用
      DateChange(type) {
        if (!type) {
          this.formLabelAlign.end_time = "";
        }
        let that = this;
        this.pickerOptions = {
          //日期禁用
          disabledDate(date) {
            return that.$tools.Time_todetermine(
              date,
              that.formLabelAlign.bengin_time
            );
          },
        };
      },
      on_particulars(row) {
          let { name, id } = row;
          this.title = name;
          this.examine_show = true;
          this.$nextTick(function () {
            this.$refs.inspectionParticulars.way_show(id);
          });
      },
      //新增管控
      on_newly() {
        let that = this;
        let { url_type, id } = that;
        ay_addrecorder(url_type, { id }).then((res) => {
          console.log(res);
          let { data } = res;
          if (data.mode == 0) {
            this.control_show = true;
            that.$nextTick(function () {
              that.$refs.newlycontrol.url_type = url_type;
              that.$refs.newlycontrol.formLabelAlign.source_id = id;
            });
          } else if (data.mode == 1) {
            let { frmcode, formvalue, info } = data;
            that.frmcode = frmcode;
            that.formvalue = formvalue;
            this.Custom_show = true;
            this.$nextTick(function () {
              that.$refs.CustomFromanalysis.way_Datadispose(info.id);
            });
          }
        });
      },
      on_reset() {
        let { formLabelAlign } = this;
        formLabelAlign.name = "";
        formLabelAlign.bengin_time = "";
        formLabelAlign.end_time = "";
        this.timeData = [];
        this.way_tableData(true);
      },
      indexMethod(index) {
        index = index + 1 + (this.page - 1) * this.page_size;
        return index;
      },
      way_tableData(isfirst) {
        if (isfirst) {
          this.page = 1;
        }
        let { formLabelAlign, page, page_size, url_type } = this;
        formLabelAlign.page = page + "";
        formLabelAlign.page_size = page_size;
        inspectionControlLog(formLabelAlign).then((res) => {
          let { data, total, catesList } = res.data;
          this.cate = catesList;
          this.total = total;
          this.table_List = data;
          if (this.enter_type == "control") {
            this.on_particulars(data[0]);
          }
        });
      },
      //分页
      way_page(val) {
        this.page = val;
        this.way_tableData();
      },
  
      on_waypop(type) {
        let that = this;
        let { url_type, id } = that;
        if (type == 1) {
          ay_addrecorder(url_type, { id }).then((res) => {
            console.log(res);
            let { data } = res;
            if (data.mode == 0) {
              this.newly_From.source_id = id;
              this.newly_show = true;
            } else if (data.mode == 1) {
              let { frmcode, formvalue, info } = data;
              that.frmcode = frmcode;
              that.formvalue = formvalue;
              this.Custom_show = true;
              this.$nextTick(function () {
                that.$refs.CustomFromanalysis.way_Datadispose(info.id);
              });
            }
          });
        } else if (type == 2) {
          this.check_From.source_id = id;
          this.check_show = true;
        }
      },
      assessChange(event) {
        let events = event[event.length - 1];
        this.assess_Data.cate_id = events;
      },
  
      //验收清除
      closed_check() {
        this.check_From.source_id = "";
        this.check_From.is_pass = "1";
        this.check_From.form_detail = "";
      },
  
      on_assess() {
        let id = this.id;
        let url = hdangerestimateInfo;
        if (this.url_type == "cases") url = casesestimateInfo;
        url({ id }).then((res) => {
          let {
            level_color_list,
            info,
            level_list,
            enable_list,
            scheme_list,
            office_position,
          } = res.data;
          let { score, level_color, status, department_id, cate_id, end_time } =
            info;
          this.level_color_list = level_color_list;
          this.level_list = level_list;
          this.enable_list = enable_list;
          this.scheme_list = scheme_list;
          this.office_position = office_position;
          this.assess_pop = true;
          this.assess_Data.scheme_id = scheme_list[0].id;
          this.assess_Data.id = id;
          this.assess_Data.score = score;
          this.assess_Data.status = status;
          this.assess_Data.end_time = end_time;
          this.assess_Data.level_color = level_color || "";
          this.assess_Data.cate_id = cate_id || "";
          if (department_id) {
            this.assess_Data.department_id = department_id;
            this.$nextTick(function () {
              this.$refs.assess_Bepartment.bepartment_List = department_id;
            });
          }
        });
      },
  
      on_preserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let url = hdangerestimateSave;
            if (this.url_type == "cases") url = casesestimateSave;
            let { assess_Data } = this;
            let From_Data = JSON.parse(JSON.stringify(assess_Data));
            From_Data.office_position = assess_Data.checkedCities
              .map((item) => item.id)
              .join(",");
            delete From_Data.checkedCities;
            From_Data.end_time = this.$tools.getNextDate(From_Data.end_time);
            url(From_Data).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.way_tableData();
                this.assess_pop = false;
              }
            });
          } else {
            return false;
          }
        });
      },
  
      //整改保存
      on_newlypreserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let newly_From = JSON.parse(JSON.stringify(this.newly_From));
            newly_From.vedio = newly_From.video_List.join(",");
            newly_From.images = newly_From.img_List.join(",");
            delete newly_From.video_List;
            delete newly_From.img_List;
            let url_type = this.url_type;
            ay_recorderSave(url_type, newly_From).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                let that = this;
                setTimeout(function () {
                  that.newly_show = false;
                }, 1000);
              }
            });
          } else {
            return false;
          }
        });
      },
  
      //验收保存
      on_checkserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let { check_From } = this;
            let url = hdangercheckpass;
            if (this.url_type == "cases") url = casescheckpass;
            url(check_From).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                let that = this;
                that.way_tableData();
                setTimeout(function () {
                  that.check_show = false;
                }, 1000);
              }
            });
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .drawer-more {
    & > div {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      font-size: 16px;
      align-items: center;
    }
    .p1 {
      width: 100px;
      margin-right: 10px;
    }
  }
  .imgsList {
    display: flex;
    & > div {
      width: 120px;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .describe {
    display: inline-block;
    position: relative;
    padding: 4px;
    font-size: 16px;
    color: #4f7afd;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .describe:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4f7afd;
  }
  .from {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .form-inp {
    width: 200px;
  }
  .paging {
    text-align: center;
    padding: 10px;
  }
  .tab_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    button {
      margin-right: 10px;
    }
    p {
      display: flex;
      align-items: center;
    }
  }
  .span1 {
    font-size: 14px;
    color: #000000;
  }
  .span2 {
    font-size: 14px;
    margin-left: 10px;
    color: #797979;
  }
  .span_i {
    font-size: 14px;
    color: #797979;
    margin-left: 10px;
    cursor: pointer;
  }
  .el-divider__text {
    color: #0080ff;
    font-weight: bold;
  }
  .segmentation {
    height: 1px;
    background-color: #f4f4f4;
    margin-bottom: 20px;
  }
  .list_sty {
    margin-right: 10px;
  }
  .search_button {
    display: flex;
    margin-bottom: 18px;
    flex-direction: column-reverse;
  }
  
  .entirety {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .swiper-sty {
    flex: 1;
  }
  
  .tab {
    background-color: #ffffff;
    display: flex;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .tab-z {
    height: 80px;
    line-height: 80px;
    text-align: center;
    flex: 1;
    color: #6e747a;
  }
  
  .pitch {
    position: relative;
    color: #3e4650;
  }
  
  .pitch:before {
    border-radius: 4px;
    content: "";
    position: absolute;
    bottom: 0px;
    width: 20%;
    height: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0788ff;
  }
  
  .title-name {
    font-size: 35px;
    color: #2d2d2d;
  }
  
  .title-time {
    font-size: 20px;
    color: #c3c3c3;
  }
  
  .title-content {
    margin: 10px 0;
    font-size: 25px;
    color: #c3c3c3;
  }
  
  .describe-name {
    display: flex;
    align-items: center;
  }
  
  .describe-name view {
    margin-left: 10px;
    font-size: 28px;
    color: #2d2d2d;
  }
  
  .describe-name image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  
  .describe-state {
    font-size: 28px;
    color: #5abf97;
  }
  
  .describe {
    padding: 30px;
  }
  .describe-title {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #f1f1f1;
  }
  .describe-title > view {
    color: #333333;
  }
  .describe-xx {
    padding: 20px 0;
    color: #8799a3;
    border-bottom: 1px solid #f1f1f1;
  }
  .imgs {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
  }
  .imgs > image {
    width: 23%;
    height: 150px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
  
    /* border-radius:8px; */
  }
  
  
  .iconsty {
    color: #0073ff !important;
  }
  .iconsty text {
    color: #0073ff !important;
  }
  
  .all {
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 20px;
    background-color: #ffffff;
    margin-top: 20px;
  }
  
  .titles {
    padding: 20px;
    font-weight: bold;
    color: #1cbbb4;
    border-bottom: 1px solid #f1f1f1;
  }
  .BUTT {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #f1f1f1;
  }
  .radio-text {
    margin-left: 10px;
  }
  
  .textname {
    padding: 0 40px;
    margin-top: 10px;
    font-weight: 25px;
  }
  .add-sty {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    & > p {
      font-weight: bold;
      color: #409eff;
      font-size: 16px;
    }
  }
  
  .message {
    display: flex;
    font-size: 15px;
    color: #28307c;
    margin-top: 10px;
    .p1 {
      color: #465269;
    }
  }
  .imgs {
    display: flex;
    .el-image {
      width: 120px;
      height: 120px;
      margin-right: 10px;
    }
  
    img {
      width: 100%;
      height: 100%;
    }
  }
  .el-col-12 {
    height: 51px;
  }
  </style>
  