<template>
    <div class="table_sty">
      <div class="froms" style="margin: 5px">
        <div>基本信息</div>
        <div class="wire"></div>
  
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="message">
              <p>检查名称：</p>
              <p class="p1">{{ data.name }}</p>
  
              <div class="sign">
                <div class="sign-1" :style="{ background: data.back_color }">
                  {{ data.level_type }}
                </div>
                <div
                  class="sign-2"
                  :style="{
                    border: `1px  solid ${data.back_color}`,
                    color: data.back_color,
                  }"
                >
                  {{ data.level_color }}
                </div>
              </div>
            </div>
          </el-col>        
          <el-col :span="12">
            <div class="message">
              <p>起始日期：</p>
              <p class="p1">{{ `${data.begin_time}-${data.end_time}` }}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="message">
              <p>检查频率：</p>
              <p class="p1">{{ check_times[data.check_times].name }}</p>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="message">
              <p>检查描述：</p>
              <p class="p1">{{ data.desc }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
  
      <div class="froms particulars-pop">
        <inspectionLogs :id="id" url_type="activity" ref="inspectionLogs" />
      </div>
    </div>
  </template>
  
  <script>
  import { inspectiondetail } from "../../assets/request/api";
  // import tablepop from "../../components/controlog/activity_logs";
  import inspectionLogs from "../../components/controlog/inspection_logs";
  export default {
    name: "inspection_particulars",
    components: { inspectionLogs },
    data() {
      return {
        id: "",
        data: {},
        check_times:[],
      };
    },
    created() {
      let { id } = this.$route.query;
      this.id = id;
      inspectiondetail({ id }).then((res) => {
        let {info,check_times}  = res.data
        this.data = info;
        this.check_times =check_times
      });
    },
    methods: {},
  };
  </script>
  <style lang="less" scoped>
  .message {
    display: flex;
    font-size: 15px;
    color: #28307c;
    margin-top: 10px;
    .p1 {
      color: #465269;
    }
  }
  .describe {
    display: inline-block;
    position: relative;
    padding: 4px;
    font-size: 16px;
    color: #0788ff;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .describe:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 4px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0788ff;
  }
  .segmentation {
    height: 1px;
    background-color: #f4f4f4;
    margin-bottom: 20px;
  }
  .result {
    display: flex;
    align-items: center;
    & > div {
      margin-right: 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #19be6b;
    }
  }
  .wire {
    height: 1px;
    background: #e8eaec;
    margin: 10px -15px 0 -15px;
  }
  .sign {
    margin-left: 30px;
    display: flex;
    div {
      width: 30px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 9px;
      color: #ffffff;
      box-sizing: border-box
    }
  }
  
  .sign-1 {
    border-radius: 5px 0 0 5px;
  }
  .sign-2 {
    border-radius: 0 5px 5px 0;
  }
  </style>
  